<template>
  <div class="entryBox">
    <div class="entry">
      <div class="entryItem" v-for="(item,index) in list" :key="index" @click="entryItem(item)">{{item}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "entryIndex",
  data() {
    return {
      list: ['普通问答','产品问答','健康咨询']
    }
  },
  methods: {
    entryItem(item) {
      if(item === '普通问答') {
        this.$router.push({ path: '/general' })
      }else if(item === '产品问答') {
        this.$router.push({ path: '/product' })
      }else if(item === '健康咨询') {
        this.$router.push({ path: '/product2' })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.entryBox {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  .entry {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .entryItem {
      width: 240px;
      height: 120px;
      background-color: #fff;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-weight: bold;
      margin: 20px 30px;
      cursor: pointer;
      box-shadow: 10px 10px 10px #cccccc;
    }
  }
}
</style>
