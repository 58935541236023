<template>
  <div class="product">
    <div style="height: 100vh;display: flex;align-items: center;" v-if="show">
      <div style="position: relative;" @keyup.enter="search">
        <el-input
            id="elInput"
            type="textarea"
            :rows="4"
            placeholder="请输入内容"
            v-model="textarea"
            style="width: 650px;text-align: center;font-size: 16px;color: black;">
        </el-input>
        <i class="el-icon-s-promotion" @click="search" style="font-size: 20px;position: absolute;bottom: 12px;right: 20px;color: #adacac;"></i>
      </div>
    </div>

    <div class="content" v-else>
      <el-steps :active="active" :align-center="true" finish-status="success" style="position: absolute;width: 650px;margin-top: 15px;">
        <el-step title="已开始"></el-step>
        <el-step title="进行中"></el-step>
        <el-step title="已完成"></el-step>
      </el-steps>
      <div class="left-content">
        <!-- 标题和内容 -->
        <div>
          <div>
            <h2 v-html="textarea" style="display: inline-block;"></h2>
            <el-tooltip content="追问" placement="bottom">
              <i class="el-icon-chat-dot-round" @click="back" style="font-size: 22px;margin-left: 10px;color: #67c23a;"></i>
            </el-tooltip>
          </div>
          <div v-html="content"></div>
        </div>
        <!-- 来源 -->
        <div>
          <h3 v-if="showSource">来源</h3>
          <ul>
            <li  class="ASourceBox"  v-for="(item, index) in source" :key="index">
              <a href="#" class="ASource">
                <div v-html="item"></div>
              </a>
            </li>
          </ul>
        </div>
      </div>

<!--      <div class="right-content">-->
<!--        <div class="rightContent-top">-->
<!--          <div style="width: 100%;height:100%;display:flex;align-items: center;padding: 0 15px;">-->
<!--            <span style="font-weight: bold;">脑图</span>-->
<!--            <i class="el-icon-s-operation" style="font-size: 20px;margin-left: 15px;"></i>-->
<!--            <i class="el-icon-s-unfold" style="font-size: 20px;margin-left: 15px;"></i>-->
<!--          </div>-->
<!--          <div>-->
<!--            <i class="el-icon-close" style="font-size: 20px;margin-right: 15px;"></i>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="rightContent-center">hhh</div>-->
<!--        <div class="rightContent-bottom">生成演示文稿</div>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import SSEClient from 'sse-web-client'
import MarkdownIt from 'markdown-it'
import Prism from 'prismjs'
import 'prismjs/themes/prism.css'
import markdownItPrism from 'markdown-it-prism'
import 'prismjs/components/prism-bash'
export default {
  name: "productIndex",
  data() {
    return {
      typeId: 1,
      textarea: '',
      content: '',
      show: true,
      showSource: false,
      source: [],
      answer: '',
      answerDom: [],
      active: 1
    }
  },
  methods: {
    back() {
      // 点击追问 重置所有数据
      this.textarea = ''
      this.show = true
      this.showSource = false
      this.content = ''
      this.source = []
      this.answer = ''
      this.answerDom = []
      this.active = 1
    },
    search() {
      // 判断输入框是否为空
      if(this.textarea === '' || this.textarea === null || this.textarea.length === 0 || this.textarea.indexOf('\n') === 0) {
        this.$message.warning('搜索内容不能为空~')
      }else {
        this.show = false
        // 连接sse
        const client = new SSEClient()
        let md = new MarkdownIt({
          html: true,
          linkify: true,
          typographer: true,
          highlight: function(str, lang) {
            if(lang && Prism.languages[lang]) {
              try {
                return Prism.highlight(str, Prism.languages[lang], lang)
              }catch(e) {
                console.error(`Prism highlight failed for language "${lang}"`, e)
              }
            }
            return ''
          }
        }).use(markdownItPrism, {
          languages: ['javascript','python','yaml','markdown','prism-markdown','C++','C#','Java','PHP','HTML5','CSS','SQL','Bash','TypeScript','PowerShell']
        })
        // document类型是来源
        client.addEventListener('document', (data) => {
          const backData = JSON.parse(data)
          if(backData.code === 200) {
            this.answerDom.push(md.render(backData.data.content))
            // 手动高亮代码
            Prism.highlightAll()
            this.active = 2
          }
        })
        client.addEventListener('chat', (data) => {
          const backData = JSON.parse(data)
          // console.log(backData,'back')
          if(backData.code === 200) {
            this.answer += backData.data.content
            this.content = md.render(this.answer)
            // 手动高亮代码
            Prism.highlightAll()
            if(backData.data.finish) {
              this.showSource = true
              this.for(0)
            }
          }
        })
        client.request({
          url: process.env.VUE_APP_ADMIN_HOST + '/rest/chat/stream2',
          method: 'POST',
          data: {typeId: this.typeId, content: this.textarea, messageId: ''}
        })
      }
    },
    for(i) {
      if(i < this.answerDom.length) {
        setTimeout(() => {
          this.source.push(this.answerDom[i])
          i++
          this.for(i)
        },400)
      }else {
        setTimeout(() => {
          this.active = 3
        },200)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.product {
  display: flex;
  justify-content: center;
  .content {
    width: 100%;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    .left-content {
      width: 650px;
      background-color: #fff;
      padding: 60px 20px 0;
      border-radius: 10px;
      line-height: 35px;
      .ASource {
        color: #667085;
        font-size: 15px;
        text-decoration: none;
      }
      ::v-deep .ASource:hover {
        text-decoration: underline;
        color: black;
      }
      .ASourceBox::after {
        content: '---------------------------------------------------------------------------------------------------------------------';
        display: block;
        color: #eeeeee;
        line-height: 5px;
      }
      .ASourceBox:last-child::after {
        content: '';
        display: block;
      }
    }
    .right-content {
      width: 380px;
      display: flex;
      flex-direction: column;
      border: 1px solid #eeeeee;
      background-color: #fff;
      margin-left: 50px;
      margin-top: 50px;
      .rightContent-top {
        width: 100%;
        height: 60px;
        border-bottom: 1px solid #eeeeee;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .rightContent-center {
        width: 100%;
        flex: 1;
        padding: 15px;
      }
      .rightContent-bottom {
        width: 100%;
        height: 50px;
        border-top: 1px solid #eeeeee;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>
