<template>
  <div class="index">
    <div class="header">
      <span>{{ title }}</span>
    </div>
    <div class="container">
      <div class="dialog_box">
        <div class="dialog">
          <div class="dialogList" v-for="(item, index) in dialogList" :key="index">
            <div class="right" v-if="item.isAi === false">
              <div style="width: 40px;height: 100%;margin-right: 10px;"></div>
              <div class="right-content">{{ item.content }}</div>
              <div style="width: 40px;height: 100%;margin-left: 10px;">
                <img src="../../assets/avatar.jpg" style="width: 40px;height: 40px;border-radius: 50%;" alt="">
              </div>
            </div>
            <div class="left" v-else>
              <div style="width: 40px;height: 100%;margin-right: 10px;">
                <img src="../../assets/logo.png" style="width: 40px;height: 40px;border-radius: 50%;" alt="">
              </div>
              <div class="left-content" v-html="item.content"></div>
              <div style="width: 40px;height: 100%;margin-left: 10px;"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="input" @keyup.enter="send">
        <el-input class="el-input" v-model="input" placeholder="输入” ^ “切换场景">
          <el-button slot="append" icon="el-icon-s-promotion" @click="send" :disabled="disabled"></el-button>
        </el-input>
        <!--  切换场景  -->
        <div class="type" v-show="showType">
          <div class="type-list" v-for="item in typeList" :key="item.id" @click="selectType(item)">{{ item.content }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SSEClient from 'sse-web-client'
import MarkdownIt from 'markdown-it'
import Prism from 'prismjs'
import 'prismjs/themes/prism.css'
import markdownItPrism from 'markdown-it-prism'
import 'prismjs/components/prism-bash'

export default {
  name: "IndexLayout",
  data() {
    return {
      title: '普通问答',
      input: '',
      typeId: 1,
      dialogList: [],
      answer: '',
      timer: null,
      wordIndex: 0,
      showType: false,
      typeList: [
        {id: 1, content: 'aaa', typeId: 1},
        {id: 2, content: 'bbb', typeId: 2},
        {id: 3, content: 'ccc', typeId: 3},
        {id: 4, content: 'ddd', typeId: 4},
        {id: 5, content: 'eee', typeId: 5}
      ],
      // 假设是键盘输入
      lastInputMethod: '',
      disabled: false
    }
  },
  watch: {
    input(v) {
      const inputElement = document.getElementsByClassName('el-input')
      inputElement[0].addEventListener('keydown', this.handleKeyDown)
      if (v === '^' && this.lastInputMethod === 'keyboard') {
        console.log(true)
        this.showType = true
      } else {
        this.showType = false
      }
    }
  },
  methods: {
    // 监测是否为复制粘贴
    handleKeyDown(event) {
      // console.log(event)
      if (event.ctrlKey && event.key === 'v') {
        this.lastInputMethod = 'paste'
      } else if (event.key === '^') {
        this.lastInputMethod = 'keyboard'
      } else {
        this.lastInputMethod = ''
      }
    },
    // 切换场景
    selectType(item) {
      console.log(item.id, 'typeId')
      this.showType = false
      this.input = ''
      this.$message.success('成功切换场景' + item.content);
      // 切换typeId的值
      // 清空聊天记录
    },
    showLastMsg() {
      this.$nextTick(() => {
        let dialogDiv = document.getElementsByClassName('dialog_box')
        dialogDiv[0].scrollTop = dialogDiv[0].scrollHeight
      })
    },
    send() {
      const that = this
      this.disabled = true
      if (this.input) {
        if (this.dialogList.length === 0) {
          this.dialogList.push({isAi: false, content: this.input})
        } else {
          this.dialogList.push({
            isAi: false,
            content: this.input,
            messageId: this.dialogList[this.dialogList.length - 1].messageId
          })
        }
        this.showLastMsg()
        // 连接sse
        const client = new SSEClient()
        let md = new MarkdownIt({
          html: true,
          linkify: true,
          typographer: true,
          highlight: function (str, lang) {
            if (lang && Prism.languages[lang]) {
              try {
                return Prism.highlight(str, Prism.languages[lang], lang)
              } catch (e) {
                console.error(`Prism highlight failed for language "${lang}"`, e)
              }
            }
            return ''
          }
        }).use(markdownItPrism, {
          languages: ['javascript', 'python', 'yaml', 'markdown', 'prism-markdown', 'C++', 'C#', 'Java', 'PHP', 'HTML5', 'CSS', 'SQL', 'Bash', 'TypeScript', 'PowerShell']
        })
        this.dialogList.push({isAi: true, content: this.answer, typeId: '', messageId: ''})
        // 默认监听
        client.addListener((data) => {
          const backData = JSON.parse(data)
          if (backData.code === 200) {
            this.answer += backData.data.content
            this.dialogList[this.dialogList.length - 1].content = md.render(this.answer)
            // 手动高亮代码
            Prism.highlightAll()
            this.showLastMsg()
            if (backData.data.finish) {
              this.dialogList[this.dialogList.length - 1].typeId = backData.data.typeId
              this.dialogList[this.dialogList.length - 1].messageId = backData.data.messageId
              this.answer = ''
              this.disabled = false
            }
          }
        })
        // 监听类型为chat类型
        client.addEventListener('chat', (data) => {
          const backData = JSON.parse(data)
          if (backData.code === 200) {
            this.answer += backData.data.content
            this.dialogList[this.dialogList.length - 1].content = md.render(this.answer)
            // 手动高亮代码
            Prism.highlightAll()
            this.showLastMsg()
            if (backData.data.finish) {
              console.log(this.answer, 'answer')
              // this.for(0,md)
              this.dialogList[this.dialogList.length - 1].typeId = backData.data.typeId
              this.dialogList[this.dialogList.length - 1].messageId = backData.data.messageId

              // this.answer = ''
              this.disabled = false
            }
          }
        })
        client.request({
          url: process.env.VUE_APP_ADMIN_HOST + '/rest/chat/stream2',
          method: 'POST',
          data: {
            typeId: this.typeId,
            content: this.input,
            messageId: this.dialogList[this.dialogList.length - 2].messageId
          }
        })
        this.input = ''
      } else {
        that.$message({
          message: '发送的消息不能为空~',
          type: 'warning'
        });
      }
    },
    // for(i, md) {
    //   if(i < this.answer.length) {
    //     setTimeout(() => {
    //       this.dialogList[this.dialogList.length - 1].content += md.render(this.answer[i])
    //       i++
    //       this.for(i, md)
    //     },50)
    //   }else {
    //     this.answer = ''
    //   }
    // }
  }
}
</script>

<style lang="less" scoped>
.index {
  height: 100vh;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  display: none;
}

.type {
  width: 160px;
  height: 200px;
  position: absolute;
  bottom: 65px;
  background-color: #fff;
  border-radius: 10px;
  overflow-y: auto;
  z-index: 999;
}

.type-list {
  height: 50px;
  width: 100%;
  border-bottom: 1px solid #eeeeee;
  box-sizing: border-box;
  color: #565656;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header, .smallWindow-header {
  height: 56px;
  font-size: 14px;
  color: #060607;
  line-height: 67px;
  text-align: center;
}

.container {
  height: calc(100% - 80px);
  margin-top: 24px;
}

.smallWindow-container {
  height: 450px;
  margin-top: 24px;
}

.dialog_box {
  width: 100%;
  height: calc(100% - 120px);
  margin-bottom: 20px;
  overflow-y: auto;
}

.dialog {
  max-width: 760px;
  height: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

.smallWindow-dialog {
  width: 100%;
  height: 450px;
}

.left {
  //height: 50px;
  display: flex;
  margin-bottom: 20px;
}

.smallWindow-left {
  display: flex;
  margin-bottom: 20px;
}

.left-content {
  max-width: 640px;
  padding: 0 20px;
  border-radius: 12px;
  background-color: #fff;
  line-height: 30px;
  //white-space: pre-wrap;
}

.smallWindow-left-content {
  max-width: 300px;
  padding: 11px;
  border-radius: 12px;
  background-color: #fff;
  white-space: pre-wrap;
  border: 1px solid #cccccc;
  font-size: 14px;
}

.right-content {
  max-width: 640px;
  padding: 11px;
  border-radius: 12px;
  background-color: #2d65f7;
  color: #fff;
  line-height: 30px;
}

.smallWindow-right-content {
  max-width: 300px;
  padding: 11px;
  border-radius: 12px;
  background-color: #2d65f7;
  color: #fff;
}

.right {
  height: 50px;
  display: flex;
  justify-content: right;
  margin-bottom: 20px;
}

.smallWindow-right {
  height: 50px;
  display: flex;
  justify-content: right;
  margin-bottom: 20px;
}

.input {
  width: 680px;
  display: flex;
  margin: 0 auto;
  //justify-content: center;
  position: relative;
}

.smallWindow-input {
  width: 100%;
  display: flex;
  justify-content: center;
}

.el-input {
  width: 680px !important;
  height: 60px !important;
  margin: 0 auto;
  //border: 1px solid red;
  border-radius: 10px;
  display: flex;
  outline: none;
}

.smallWindow .el-input {
  width: 400px !important;
  height: 50px !important;
  margin: 0 auto;
  border: 1px solid #eeeeee;
  border-radius: 10px;
  display: flex;
  outline: none;
}

::v-deep .el-input__inner {
  width: 680px;
  height: 100% !important;
  border: 1px solid #DCDFE6 !important;
  border-radius: 10px 0 0 10px !important;
  padding: 0 15px;
  outline: none !important;
  border-right: 1px solid transparent !important;
}

::v-deep .smallWindow .el-input__inner {
  width: 350px;
  height: 100% !important;
  border: none !important;
  border-radius: 10px 0 0 10px !important;
  padding: 0 15px;
  outline: none !important;
  border-right: 1px solid transparent !important;
}

::v-deep .el-input-group__append {
  width: 80px;
  height: 60px !important;
  background-color: #fff !important;
  border-radius: 0 10px 10px 0 !important;
  //border: 1px solid #DCDFE6;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .smallWindow .el-input-group__append {
  width: 50px;
  height: 100% !important;
  background-color: #fff !important;
  border-radius: 0 10px 10px 0;
  border: 0px solid transparent !important;
  margin: 0;
}

.el-button--default {
  height: 100%;
  width: 100%;
  background-color: #fff;
  border: none;
  border-radius: 0 10px 10px 0;
}

.smallWindow .el-button--default {
  height: 50px;
  width: 100%;
  background-color: #fff;
  border: none;
  border-radius: 0 10px 10px 0;
  margin: 0 !important;
}

::v-deep .el-icon-s-promotion {
  font-size: 20px !important;
}

.smallWindow {
  //width: 400px;
  //height: 600px;
  //position: fixed;
  //bottom: 120px;
  //right: 70px;
}

.small_button {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  font-size: 14px;
  background-color: #2d65f7;
  position: absolute;
  right: 30px;
  bottom: 100px;
  text-align: center;
  line-height: 70px;
  color: #fff;
  cursor: pointer;
}

.chat {
  width: 400px;
  height: 600px;
  box-shadow: 5px 5px 10px #DCDFE6;
  border-radius: 20px;
  padding: 0 20px;
  background-color: #fff;
  z-index: 9999;
  position: absolute;
  bottom: 90px;
  right: 20px;
}
</style>
