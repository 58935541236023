<template>
  <div class="product2">
    <div style="height: 100vh;display: flex;align-items: center;" v-if="show">
      <div style="position: relative;" @keyup.enter="search">
        <el-input
            id="elInput"
            type="textarea"
            :rows="4"
            placeholder="请描述你的病情并询问我推荐合适的产品"
            v-model="textarea"
            style="width: 650px;text-align: center;font-size: 16px;color: black;">
        </el-input>
        <i class="el-icon-s-promotion" @click="search"
           style="font-size: 20px;position: absolute;bottom: 12px;right: 20px;color: #adacac;"></i>
      </div>
    </div>

    <div class="content" v-else>
      <el-steps :active="active" :align-center="true" finish-status="success"
                style="position: absolute;width: 650px;margin-top: 15px;">
        <el-step title="已开始"></el-step>
        <el-step title="进行中"></el-step>
        <el-step title="已完成"></el-step>
      </el-steps>
      <div class="left-content">
        <!-- 标题和内容 -->
        <div>
          <div>
            <h2 v-html="textarea" style="display: inline-block;"></h2>
            <el-tooltip content="追问" placement="bottom">
              <i class="el-icon-chat-dot-round" @click="back"
                 style="font-size: 22px;margin-left: 10px;color: #67c23a;"></i>
            </el-tooltip>
          </div>
          <div v-html="content"></div>
        </div>
        <!-- 产品 -->
        <div v-if="showProduct">
          <h3>推荐产品</h3>
          <el-carousel trigger="click" height="300px" arrow="always" :autoplay="false"
                       style="width:100%;display: flex;justify-content: center;align-items: center;">
            <el-carousel-item v-for="(item,index) in productList" :key="index">
              <div class="small" v-for="(it, idx) in item" :key="idx" style="padding: 0 60px;" v-html="it"></div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <!-- 组合 -->
        <div v-if="showProductGroup">
          <h3>推荐产品组合</h3>
          <el-carousel trigger="click" height="200px" arrow="always" :autoplay="false"
                       style="width:100%;display: flex;justify-content: center;align-items: center;">
            <el-carousel-item v-for="(item,index) in productGroupList" :key="index">
              <div class="small" v-for="(it, idx) in item" :key="idx" style="padding: 0 60px;" v-html="it"></div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SSEClient from 'sse-web-client'
import MarkdownIt from 'markdown-it'
import Prism from 'prismjs'
import 'prismjs/themes/prism.css'
import markdownItPrism from 'markdown-it-prism'
import 'prismjs/components/prism-bash'

export default {
  name: "productIndex2",
  data() {
    return {
      typeId: 3,
      textarea: '',
      content: '',
      answer: '',
      show: true,
      showProduct: false,
      showProductGroup: false,
      product: [],
      productGroup: [],
      active: 1,
      productList: [],
      productGroupList: []
    }
  },
  methods: {
    back() {
      // 点击追问 重置所有数据
      this.textarea = ''
      this.content = ''
      this.answer = ''
      this.show = true
      this.showProduct = false
      this.showProductGroup = false
      this.product = []
      this.productGroup = []
      this.active = 1
      this.productList = []
      this.productGroupList = []
    },
    search() {
      // 判断输入框是否为空
      if (this.textarea === '' || this.textarea === null || this.textarea.length === 0 || this.textarea.indexOf('\n') === 0) {
        this.$message.warning('搜索内容不能为空~')
      } else {
        this.show = false
        // 连接sse
        const client = new SSEClient()
        let md = new MarkdownIt({
          html: true,
          linkify: true,
          typographer: true,
          highlight: function (str, lang) {
            if (lang && Prism.languages[lang]) {
              try {
                return Prism.highlight(str, Prism.languages[lang], lang)
              } catch (e) {
                console.error(`Prism highlight failed for language "${lang}"`, e)
              }
            }
            return ''
          }
        }).use(markdownItPrism, {
          languages: ['javascript', 'python', 'yaml', 'markdown', 'prism-markdown', 'C++', 'C#', 'Java', 'PHP', 'HTML5', 'CSS', 'SQL', 'Bash', 'TypeScript', 'PowerShell']
        })
        // 监听
        client.addEventListener('product', (data) => {
          const backData = JSON.parse(data)
          if (backData.code === 200) {
            this.product.push(md.render(backData.data.content))
            // 手动高亮代码
            Prism.highlightAll()
          }
        })
        client.addEventListener('product-group', (data) => {
          const backData = JSON.parse(data)
          if (backData.code === 200) {
            this.productGroup.push(md.render(backData.data.content))
            // 手动高亮代码
            Prism.highlightAll()
          }
        })
        client.addEventListener('chat', (data) => {
          const backData = JSON.parse(data)
          if (backData.code === 200) {
            this.answer += backData.data.content
            this.content = md.render(this.answer)
            // 手动高亮代码
            Prism.highlightAll()
            this.active = 2
            if (backData.data.finish) {
              console.log(this.product, 'p')
              console.log(this.productGroup, 'g')
              if (this.product.length > 0) {
                this.handleProduct()
                this.showProduct = true
              }
              setTimeout(() => {
                if (this.productGroup.length > 0) {
                  this.handleProductGroup()
                  this.showProductGroup = true
                }
                this.active = 3
              }, 500)
            }
          }
        })
        client.request({
          url: 'https://admin.ai.aialphay.com/prod-api/rest/product/stream',
          method: 'POST',
          data: {typeId: this.typeId, content: this.textarea, messageId: null}
        })
      }
    },
    // 处理获取的数据，使其每2条合并成一个子数组
    handleProduct() {
      this.productList = this.product.reduce((acc, curr, index) => {
        const chunkIndex = Math.floor(index / 2)
        if (!acc[chunkIndex]) {
          acc[chunkIndex] = []
        }
        acc[chunkIndex].push(curr)
        return acc
      }, [])
    },
    handleProductGroup() {
      this.productGroupList = this.productGroup.reduce((acc, curr, index) => {
        const chunkIndex = Math.floor(index / 2)
        if (!acc[chunkIndex]) {
          acc[chunkIndex] = []
        }
        acc[chunkIndex].push(curr)
        return acc
      }, [])
    }
  }
}
</script>

<style lang="less" scoped>
.product2 {
  display: flex;
  justify-content: center;

  .content {
    width: 100%;
    padding: 20px 0;
    display: flex;
    justify-content: center;

    .left-content {
      width: 650px;
      background-color: #fff;
      padding: 60px 20px 20px;
      border-radius: 10px;
      line-height: 35px;

      ::v-deep .el-carousel__container {
        width: 100%;
      }

      ::v-deep .el-carousel__item {
        overflow-y: auto;
      }

      ::v-deep .el-carousel__arrow {
        background-color: transparent;
        color: black;
      }
    }

    .right-content {
      width: 380px;
      display: flex;
      flex-direction: column;
      border: 1px solid #eeeeee;
      background-color: #fff;
      margin-left: 50px;
      margin-top: 50px;

      .rightContent-top {
        width: 100%;
        height: 60px;
        border-bottom: 1px solid #eeeeee;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .rightContent-center {
        width: 100%;
        flex: 1;
        padding: 15px;
      }

      .rightContent-bottom {
        width: 100%;
        height: 50px;
        border-top: 1px solid #eeeeee;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>
